import React from 'react';
import { useQuery, gql } from '@apollo/client';

import HandleLoading from '@hoc/handle-loading/handle-loading';
import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './wp-styles.scss';

const PAGE_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        offerBy(slug: $slug) {
            content
        }
    }
`;


const PrivacyPolicy = () => {

  const { loading, error, data } = useQuery(PAGE_QUERY, {
    variables: {slug: 'leasing-konsumencki'}
});


  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Leasing konsumencki'
        description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
          <HandleLoading loading={loading} error={error}>

            <h1>Leasing konsumencki</h1>

            <div dangerouslySetInnerHTML={{ __html: data?.offerBy.content }}>
            </div>

          </HandleLoading>

          {/* <div className={s_text.double}>
            <div>
              <p>
                Każdy posiadacz pojazdu, bez względu na formę jego finansowania, powinien wykupić obowiązkowe ubezpieczenie
                komunikacyjne, pozwalające na legalne oraz bezpieczne przemieszczanie się na terenie kraju, jak i poza jego granicami.
              </p>
              <p>
                Przygotowana przez nas oferta pozwoli Państwu oszczędzić nie tylko czas, ale również pieniądze, których w
                większości przypadków wymaga poszukiwanie najlepszego dla siebie rozwiązania. Bez względu na to, po jakiej
                stronie się znajduję, cenię sobie kompleksowość usług, dlatego też poza propozycjami leasingowymi i kredytowymi
                pomagam swoim Klientom przy doborze najkorzystniejszej formy ubezpieczenia, dopasowanej do ich indywidualnych
                potrzeb i preferencji.
              </p>
            </div>
            <div>
              <p>
                Współpracuję z wieloma firmami ubezpieczeniowymi, co przekłada się na dostęp szerokiej oferty ubezpieczeń samochodowych:.
              </p>
              <ul className={s_text.checkmark_list}>
                <li>Ubezpieczenia komunikacyjne, czyli OC, AC, NNW, ASSISTANCE</li>
                <li>Ubezpieczenie straty finansowej GAP (Ubezpieczenie Wartości Fakturowej)</li>
              </ul>
              <p>
                Ubezpieczenie GAP jest ubezpieczeniem, które pokrywa różnicę pomiędzy wartością fakturową nowo zakupionego
                pojazdu (lub wartością z polisy AC), a jego wartością rynkową z dnia wyrządzenia szkody całkowitej, kradzieży lub pożaru.
              </p>
              <p>
                Co oznacza to w praktyce? W przypadku całkowitej szkody osoba posiadająca ubezpieczenie GAP otrzymuje wypłatę dwóch
                odszkodowań: z polisy OC/AC sprawcy oraz z tytułu GAP, które pokrywa wyżej opisaną różnicę
                wartości pojazdu, pozwalając uzyskać wartość samochodu z dnia zakupu.
              </p>
            </div>
          </div>

          <div className={s_text.double}>
            <div>
              <h2>Wyróżnia się trzy rodzaje ubezpieczenia GAP:</h2>

              <h3>GAP fakturowy</h3>
              <p>
                Jest to rozwiązanie najdroższe, jednakże również najkorzystniejsze. Przy zaistnieniu szkody całkowitej
                ubezpieczyciel wypłaca bowiem różnicę między ceną fakturową samochodu a jego wartością rynkową sprzed wypadku.
              </p>

              <h3>GAP finansowy</h3>
              <p>
                Stanowi najtańszy rodzaj polisy - pokrywa różnicę między kwotą uzyskaną z odszkodowania OC/AC a pozostałymi ratami.
              </p>

              <h3>GAP indeksowy</h3>
              <p>
                To najpopularniejsza oraz najczęściej wybierana opcja, pozwalająca uzyskać w ramach polisy odszkodowanie w wysokości 20-30% wartości samochodu.
              </p>
            </div>

            <div>
              <br/>
              <br/>
              <br/>
              <p>
                Mając na uwadze znaczące korzyści ubezpieczania GAP, zawsze zachęcam moich Klientów
                do jego zakupu do każdego ubezpieczenia AC, zarówno w przypadku samochodów za gotówkę, w leasing lub na kredyt.
              </p>
              <p>
                Po więcej informacji dotyczących ubezpieczeń samochodowych serdecznie zapraszam do kontaktu
                telefonicznego i e-mailowego. Pozostaję do Waszej dyspozycji oraz służę swoją wiedzą i wsparciem!
              </p>
            </div>
          </div> */}


        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
